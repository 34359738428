import React, { useContext } from "react"

import { InteractionsContext } from "@contexts/interactions"

import "./Toasters.scss"

function Toasters() {
  const { toasters, removeToaster } = useContext(InteractionsContext)

  return (
    <div className="Toasters">
      {toasters.map(({ id, title, texte, type }) => (
        <div
          className={`Toaster ${type ? `Toaster--${type}` : undefined}`}
          key={id}
          onClick={() => removeToaster(id)}
        >
          <div className="Toaster-sign" />
          <div className="Toaster-texte">
            <h3>{title}</h3>
            <p>{texte}</p>
          </div>
          <div className="Toaster-close" />
        </div>
      ))}
    </div>
  )
}

export default Toasters

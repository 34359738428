import React, { useState } from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"

import "./Header.scss"

function HeaderLang() {
  const [langDeploy, setlangDeploy] = useState(false)

  const LANG_HEIGHT = 40

  function handleClick() {
    setlangDeploy(!langDeploy)
  }

  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => (
        <div className={`HeaderLang ${langDeploy && "active"}`}>
          <div className="HeaderLang-actual" onClick={() => handleClick()}>
            <span className="HeaderLang-actual-lang">{currentLocale.toUpperCase()}</span>
            <span className="HeaderLang-actual-close"></span>
          </div>
          <div
            className="HeaderLang-list"
            style={{
              height: langDeploy
                ? languages.length * LANG_HEIGHT + "px"
                : LANG_HEIGHT + "px",
            }}
          >
            <ul>
              {languages.map(
                language =>
                  currentLocale !== language && (
                    <li key={language} onClick={() => changeLocale(language)}>
                      {language.toUpperCase()}
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      )}
    </IntlContextConsumer>
  )
}

export default HeaderLang

import React from "react"

import { InteractionsProvider } from "@contexts/interactions"

import Header from "@components/nav/header/Header"
import Footer from "@components/nav/footer/Footer"
import Toasters from "@components/modals/toasters/Toasters"

import "@styles/global.scss"

const Layout = ({ children }) => {
  return (
    <InteractionsProvider>
      <main>
        <Header />
          {children}
          <Toasters />
        <Footer />
      </main>
    </InteractionsProvider>
  )
}

export default Layout

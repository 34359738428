import React, { useState } from "react"

import { injectIntl, Link } from "gatsby-plugin-intl"

import "./HeaderMobile.scss"

function HeaderMobile({intl}) {
  const [menuDeploy, setmenuDeploy] = useState(false)

  return (
    <div className={`HeaderMobile ${menuDeploy && "active"}`}>
      <div
        className="HeaderMobile-burger"
        onClick={() => setmenuDeploy(!menuDeploy)}
      >
        <span className="HeaderMobile-burger-line HeaderMobile-burger-line--top" />
        <span className="HeaderMobile-burger-line HeaderMobile-burger-line--middle" />
        <span className="HeaderMobile-burger-line HeaderMobile-burger-line--bottom" />
      </div>
      <div className="HeaderMobile-panel">
        <ul className="HeaderMobile-link">
          <li className="HeaderMobile-links">
            <Link to="/" activeClassName="active">
              {intl.formatMessage({ id: "home" })}
            </Link>
          </li>
          <li className="HeaderMobile-links">
            <Link to="/services" activeClassName="active">
              {intl.formatMessage({ id: "services" })}
            </Link>
          </li>
          <li className="HeaderMobile-links">
            <Link to="/marques" activeClassName="active">
              {intl.formatMessage({ id: "brands" })}
            </Link>
          </li>
          <li className="HeaderMobile-links">
            <Link to="/contact" activeClassName="active">
              {intl.formatMessage({ id: "contact" })}
            </Link>
          </li>
        </ul>
        <div className="HeaderMobile-phone">
            <a href="tel:+33 9 82 99 06 09">+33 9 82 99 06 09</a>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(HeaderMobile)

import React, { createContext, useState } from "react"
import { arrayOf, element, object, oneOfType, any } from "prop-types"

const defaultInteractionsContext = {
  toasters: [],
  addToaster: () => {},
  removeToaster: () => {},
}

const InteractionsContext = createContext(defaultInteractionsContext)

InteractionsProvider.propTypes = {
  children: oneOfType([arrayOf(element), object, any]).isRequired,
}

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

function InteractionsProvider({ children }) {

  const [toasters, setToasters] = useState([])

  function addToaster(title, texte, type = null) {
    const id = uuidv4()
    const newToaster = [{ title, texte, type, id }]
    setToasters([...toasters, ...newToaster])
  }

  function removeToaster(id) {
    const newToaster = toasters.filter(toaster => toaster.id !== id)
    setToasters([...newToaster])
  }

  return (
    <InteractionsContext.Provider
      value={{
        toasters,
        addToaster,
        removeToaster,
      }}
    >
      {children}
    </InteractionsContext.Provider>
  )
}

export { InteractionsProvider, InteractionsContext }

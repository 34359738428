import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import "./Footer.scss"

function Footer({ intl }) {
  return (
    <div className="Footer">
      <div className="container">
        <div className="Footer-side">
          <div className="edito">
            <p>
              <span className="light">
                LaboServices
                <br />
                <br />
                {intl.formatMessage({ id: "footer_description" })}
              </span>
              <br />
              <br />
              {intl.formatMessage({ id: "footer_right" })}
            </p>
          </div>
        </div>
        <div className="Footer-side">
          <div className="edito">
            <p>
              <span className="light">
                contact@laboservices.eu
                <br />
                +33 9 82 99 06 09
                <br />
                <br />
                7 Allée de Chartres
                <br />
                33000 Bordeaux
                <br />
                FRANCE
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Footer)

import React from "react"

import { injectIntl, Link } from "gatsby-plugin-intl"

import "./Header.scss"

import HeaderLang from "@components/nav/header/HeaderLang"
import HeaderMobile from "@components/nav/header/header-mobile/HeaderMobile"

import LogoHeader from "@images/logos/logo_blue.svg"

function Header({intl}) {
  return (
    <nav className="Header">
      <div className="container">
        <div className="Header-logo">
          <Link to="/">
            <img src={LogoHeader} alt="Labo Services" />
          </Link>
        </div>
        <div className="Header-side">
          <ul className="Header-link">
            <li className="Header-links">
              <Link to="/" activeClassName="active">
                {intl.formatMessage({ id: "home" })}
              </Link>
            </li>
            <li className="Header-links">
              <Link to="/services" activeClassName="active">
                {intl.formatMessage({ id: "services" })}
              </Link>
            </li>
            <li className="Header-links">
              <Link to="/marques" activeClassName="active">
                {intl.formatMessage({ id: "brands" })}
              </Link>
            </li>
            <li className="Header-links">
              <Link to="/contact" activeClassName="active">
                {intl.formatMessage({ id: "contact" })}
              </Link>
            </li>
          </ul>
          <HeaderLang />
          <div className="Header-phone">
            <a href="tel:+33 9 82 99 06 09">+33 9 82 99 06 09</a>
          </div>
          <HeaderMobile />
        </div>
      </div>
    </nav>
  )
}

export default injectIntl(Header)
